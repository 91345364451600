// Packages
import { Link } from "react-router-dom";

// Material UI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// Icons Material
import HomeIcon from '@mui/icons-material/Home';

// Components
import User from 'components/header/User';

export default function Header() {
  const headerMinHeight = '120px';

  return (
    <AppBar position="static" sx={{
      background: 'transparent',
      boxShadow: 'none',
      color: 'text.primary',
    }}>
      <Container sx={{
        padding: {
          xs: 0,
        },
      }}>
        <Toolbar sx={{
          minHeight: {
            xs: headerMinHeight,
          },
        }}>
          <Box sx={{
            width: '100%',
            borderBottom: 'solid 1px grey',
            display: 'flex',
            minHeight: headerMinHeight,
            alignItems: 'center',
          }}>

            {/* Link to Home */}
            <Box sx={{
              display: 'flex',
              flexGrow: 1,
            }}>
              <Link to="/" style={{
                display: 'contents',
              }}>
                <Box sx={{
                  color: 'primary.main',
                  display: 'contents',
                }}>
                  <HomeIcon fontSize="large" />

                  <Typography variant="h1" sx={{
                    fontSize: '2em',
                    ml: '0.2em',
                  }}>
                    Funny Movies
                  </Typography>
                </Box>
              </Link>
            </Box>
            {/* Link to Home */}

            {/* User section */}
            <User />
            {/* User section */}

          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
