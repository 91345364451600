// Packages
import { useDispatch } from 'react-redux';
import { useState } from 'react';

// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// React Hook Form Mui
import {
  FormContainer,
  TextFieldElement,
  PasswordElement,
} from 'react-hook-form-mui';

// Store
import {
  logInWithToken,
} from 'app/userSlice';

// Services
import {
  login,
  register,
} from 'services/Users';

export default function LoginRegister() {
  const dispatch = useDispatch();

  const defaultValues = {};

  const [error, setError] = useState();
  const [fetching, setFetching] = useState();

  const callLogin = async (data) => {
    const pLogin = await login(data);

    if (pLogin.error) {
      // Catch error and set error message
      switch (pLogin.error.statusCode) {
        case 401:
          // Login failed, register
          await callRegister(data);
          break;
        default:
          // Find detail message
          if (pLogin.error.details && pLogin.error.details.length) {
            setError(pLogin.error.details[0]);
          } else {
            setError(pLogin.error);
          }
          break;
      }
    } else if (pLogin.token) {
      // Login successfully
      const token = pLogin.token;
      dispatch(logInWithToken({
        token: token,
        email: data.email,
      }));
    } else {
      setError({
        message: 'Unknown error',
      });
    }

    return pLogin;
  }

  const callRegister = async (data) => {
    const pRegister = await register(data);

    if (pRegister.error) {
      // Catch error and set error message
      switch (pRegister.error.statusCode) {
        default:
          // Find detail message
          if (pRegister.error.details && pRegister.error.details.length) {
            setError(pRegister.error.details[0]);
          } else {
            setError(pRegister.error);
          }
          break;
      }
    } else {
      // Register successfully, re-login after 1 second
      setTimeout(() => {
        callLogin(data);
      }, 1000);
    }
  }

  const onSubmit = async data => {
    // Only fetch once a time
    if (!fetching) {
      // Reset error
      setError();

      // Turn on fetching
      setFetching(true);

      // Call login
      await callLogin(data);

      // Turn off fetching
      setFetching(false);
    }

    // dispatch(toggleLoggedIn());
  }

  return (
    <Box
      sx={{
        mr: -1,
        '& form > :not(style)': { m: 1, width: '20ch' },
      }}
    >
      <FormContainer onSuccess={onSubmit} defaultValues={defaultValues}>
        <TextFieldElement
          required
          type={'email'}
          margin={'dense'}
          label={'Email'}
          name={'email'}
          size="small"
          disabled={fetching}
        />
        <PasswordElement
          margin={'dense'}
          label={'Password'}
          required
          name={'password'}
          size="small"
          disabled={fetching}
        />
        <Button
          type={'submit'}
          color={'primary'}
          variant={'contained'}
          disabled={fetching}
        >Login / Register</Button>
      </FormContainer>

      {error &&
        <Typography sx={{
          ml: 1,
          color: 'secondary.main',
        }}>{error.message}</Typography>
      }
    </Box>
  );
}
