export async function getYoutubeVideoInfo(hash) {
  const
    url = `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${hash}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`;

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}