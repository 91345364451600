import { createSlice } from '@reduxjs/toolkit';

const user = JSON.parse(localStorage.getItem('user'));

let initialState = {
  isLoggedIn: false,
  email: false,
  token: false,
};

// Init user with data from localStorage
if (user) {
  initialState = user;
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Toggle isLoggedIn for testing the template
    toggleLoggedIn: (state) => {
      state.isLoggedIn = !state.isLoggedIn;
    },
    // Log in with token
    logInWithToken: (state, action) => {
      const email = action.payload.email;
      const token = action.payload.token;

      state.isLoggedIn = true;
      state.email = email;
      state.token = token;

      localStorage.setItem('user', JSON.stringify({
        isLoggedIn: true,
        email: email,
        token: token,
      }));
    },
    // Logout
    logout: (state) => {
      state.isLoggedIn = false;
      state.email = false;
      state.token = false;

      localStorage.removeItem('user')
    },
  },
});

export const selectIsLoggedIn = (state) => state.user.isLoggedIn;
export const selectEmail = (state) => state.user.email;
export const selectToken = (state) => state.user.token;

export const { toggleLoggedIn, logInWithToken, logout } = userSlice.actions;

export default userSlice.reducer;