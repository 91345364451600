// Packages
import { useSelector } from 'react-redux';

// Components
import LoginRegister from 'components/header/LoginRegister';
import UserControl from 'components/header/UserControl';

// Store
import {
  selectIsLoggedIn,
} from 'app/userSlice';

export default function User() {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  // Show login / register form if not logged in
  // or user control if logged in
  return !isLoggedIn ? <LoginRegister /> : <UserControl />;
}
