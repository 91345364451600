// Packages
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

// Store
import {
  selectIsLoggedIn,
  selectEmail,
  selectToken,
} from 'app/userSlice';

// React Hook Form Mui
import {
  FormContainer,
  TextFieldElement,
} from 'react-hook-form-mui';

// Services
import {
  shareVideo,
} from 'services/Videos';

export default function Share() {
  const navigate = useNavigate();

  const defaultValues = {};

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const email = useSelector(selectEmail);
  const token = useSelector(selectToken);

  useEffect(() => {
    // Redirect to Home if not logged in
    if (!isLoggedIn) {
      navigate('/', { replace: true, });
    }
  }, [isLoggedIn, navigate]);

  const [error, setError] = useState();
  const [fetching, setFetching] = useState();

  const callShareVideo = async (data) => {
    data.email = email;
    const pShare = await shareVideo(token, data);

    if (pShare.error) {
      // Catch error and set error message
      switch (pShare.error.statusCode) {
        default:
          // Find detail message
          if (pShare.error.details && pShare.error.details.length) {
            setError(pShare.error.details[0]);
          } else {
            setError(pShare.error);
          }
          break;
      }
    } else {
      // Share video successfully, redirect to homepage
      navigate('/', { replace: true, });
    }

    return pShare;
  }

  const onSubmit = async data => {
    // Only fetch once a time
    if (!fetching) {
      // Reset error
      setError();

      // Turn on fetching
      setFetching(true);

      // Call share
      await callShareVideo(data);

      // Turn off fetching
      setFetching(false);
    }

    // dispatch(toggleLoggedIn());
  }

  // Return false if not logged in
  if (!isLoggedIn) {
    return false;
  }

  return (
    <Box sx={{
      mt: 12,
    }}>
      <Typography variant="h2" sx={{
        fontSize: '1.2em',
      }}>
        Share a Youtube movie
      </Typography>

      <Paper variant="outlined" sx={{
        mt: 2,
        px: 2,
        py: 6,
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} sx={{
            lineHeight: '40px',
            textAlign: 'right',
          }}>
            Youtube URL:
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormContainer onSuccess={onSubmit} defaultValues={defaultValues}>
              <TextFieldElement
                required
                margin={'dense'}
                name={'youtubeUrl'}
                size="small"
                sx={{
                  mt: 0,
                  width: '100%',
                }}
                disabled={fetching}
              />
              <Button
                type={'submit'}
                color={'primary'}
                variant={'outlined'}
                sx={{
                  mt: 2,
                  width: '100%',
                }}
                disabled={fetching}
              >Share</Button>
            </FormContainer>
          </Grid>
        </Grid>
      </Paper>

      {error &&
        <Typography sx={{
          mt: 2,
          color: 'secondary.main',
        }}>{error.message}</Typography>
      }
    </Box>
  );
}
