// Packages
import { useEffect, useState } from 'react';

// Material UI
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import VideoItem from 'components/videos/VideoItem';

// Services
import {
  getVideos,
} from 'services/Videos';

export default function Home() {
  const [error, setError] = useState();
  const [fetching, setFetching] = useState();
  const [videos, setVideos] = useState([]);

  const fetchVideos = async () => {
    // Only fetch once a time
    if (!fetching) {
      // Reset error
      setError();

      // Turn on fetching
      setFetching(true);

      const gVideos = await getVideos();

      if (gVideos.error) {
        // Catch error and set error message
        setError(gVideos.error);
      } else {
        setVideos(gVideos);
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  useEffect(() => {
    fetchVideos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Show message if error
  if (error) {
    return (
      <Box sx={{
        mt: 6,
        textAlign: 'center',
        color: 'secondary.main',
      }}>
        {error.message}
      </Box>
    )
  }

  // Render video list
  return (
    <Box>
      {/* Show loading icon when fetching */}
      {fetching &&
        <Box sx={{
          mt: 6,
          textAlign: 'center',
        }}>
          <CircularProgress />
        </Box>
      }

      {/* Render video list */}
      {!fetching &&
        <>
          {/* Empty */}
          {videos.length === 0 &&
            <Box sx={{
              mt: 6,
              textAlign: 'center',
            }}>
              Not found any videos.
            </Box>
          }

          {/* Has videos */}
          {videos.length > 0 &&
            videos.map((video) => (
              <VideoItem key={video.id} video={video} />
            ))
          }
        </>
      }
    </Box>
  );
}
