export async function login(data) {
  const
    url = `${process.env.REACT_APP_API_URL}users/login`;

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}

export async function register(data) {
  const
    url = `${process.env.REACT_APP_API_URL}users/signup`;

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const result = await res.json();
    return result;
  }
  catch (error) {
    console.error(error);
    return error;
  }
}