// Packages
import { Routes, Route } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

// Components
import Header from 'components/header/Header';

// Pages
import Home from 'pages/Home';
import NotFound from 'pages/NotFound';
import Share from 'pages/Share';

// Css
import '@fontsource/roboto';

export default function App() {
  return (
    <>
      <Header />

      <Box component="main" sx={{
        mt: 3,
      }}>
        <Container sx={{
          maxWidth: {
            xs: '800px',
          },
        }}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/share" element={<Share />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Container>
      </Box>
    </>
  );
}
