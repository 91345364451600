// Packages
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Store
import {
  logout,
  selectEmail,
} from 'app/userSlice';

export default function UserControl() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        mr: -1,
        '& > :not(style)': { m: 1, width: '20ch' },
      }}
      autoComplete="off"
    >
      <Typography variant="body1" sx={{
        display: 'inline',
      }}>
        Welcome {useSelector(selectEmail)}
      </Typography>

      <Button
        variant="outlined"
        onClick={() => {
          navigate('/share', { replace: true, });
        }}
      >Share a movie</Button>

      <Button
        variant="contained"
        onClick={() => { dispatch(logout()) }}
      >Logout</Button>
    </Box>
  );
}
