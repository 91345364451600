// Packages
import { useEffect, useState } from 'react';

// Material UI
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Icons Material
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

// Libraries
import { youtubeParser } from 'libraries/Youtube';

// Services
import { getYoutubeVideoInfo } from 'services/Youtubes';

export default function VideoItem(props) {
  const video = props.video;

  const [error, setError] = useState();
  const [fetching, setFetching] = useState();
  const [youtubeVideoInfo, setyoutubeVideoInfo] = useState({
    title: 'Movie Title (undefined)',
    description: 'Movie Description (undefined)',
  });

  const fetchYoutubeVideoInfo = async (hash) => {
    // Only fetch once a time
    if (!fetching) {
      // Turn on fetching
      setFetching(true);

      const gYoutubeVideoInfo = await getYoutubeVideoInfo(hash);

      if (gYoutubeVideoInfo.error) {
        // Catch error and set error message
        setError(gYoutubeVideoInfo.error);
      } else if (
        gYoutubeVideoInfo.items
        && gYoutubeVideoInfo.items.length
        && gYoutubeVideoInfo.items[0]
        && gYoutubeVideoInfo.items[0].snippet
      ) {
        const snippet = gYoutubeVideoInfo.items[0].snippet;
        setyoutubeVideoInfo({
          title: snippet.title,
          description: snippet.description,
        });
      }

      // Turn off fetching
      setFetching(false);
    }
  }

  useEffect(() => {
    if (video) {
      const hash = youtubeParser(video.youtubeUrl);

      if (hash) {
        fetchYoutubeVideoInfo(hash);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check video
  if (!video) {
    return false;
  }

  // Check Youtube URL hash
  const hash = youtubeParser(video.youtubeUrl);
  if (!hash) {
    return false;
  }

  return (
    <Grid container spacing={2} sx={{
      mb: 4,
      "& iframe": {
        width: "100%",
        height: {
          xs: "50vw",
          md: "210px",
        },
      },
    }}>
      <Grid item xs={12} md={6}>

        {/* Embed video */}
        <iframe width="800" src={`https://www.youtube.com/embed/${hash}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        {/* Embed video */}

      </Grid>
      <Grid item xs={12} md={6} sx={{
        '& .react .each': {
          display: 'inline-block',
          mr: 2,

          '& p': {
            display: 'inline-block',
          },

          '& .MuiSvgIcon-root': {
            fontSize: '1.2em',
            ml: '3px',
            mb: '-3px',
          },
        },
      }}>

        {/* Show loading icon when fetching */}
        {fetching &&
          <Box sx={{
            textAlign: 'center',
          }}>
            <CircularProgress />
          </Box>
        }

        {/* Title */}
        {!fetching &&
          <Typography variant="h2" sx={{
            color: error ? 'secondary.main' : 'primary.main',
            fontSize: '1.2em',
            fontWeight: 'bold',
          }}>
            {error && error.message}

            {!error && youtubeVideoInfo.title}
          </Typography>
        }
        {/* Title */}

        {/* Shared by */}
        <Typography variant="body1">
          Shared by: {video.email}
        </Typography>
        {/* Shared by */}

        {/* React */}
        <Box className='react' sx={{
          // Temporary hide it
          display: 'none',
        }}>
          <Box className='each'>
            <Typography variant="body1">
              89
            </Typography>
            <ThumbUpIcon />
          </Box>

          <Box className='each'>
            <Typography variant="body1">
              12
            </Typography>
            <ThumbDownIcon />
          </Box>
        </Box>
        {/* React */}

        {/* Description */}
        <Typography variant="body1" sx={{
          mt: 1,
        }}>
          Description:
        </Typography>

        {!fetching &&
          <>
            {error &&
              <Typography variant="body2" color="secondary.main">
                {error.message}
              </Typography>
            }

            {!error &&
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{ __html: youtubeVideoInfo.description.replace(/(\r\n|\n\r|\r|\n)/g, '<br>') }}
                sx={{
                  overflow: 'hidden',
                  maxHeight: '10em',
                }}
              />
            }
          </>
        }
        {/* Description */}

      </Grid>
    </Grid >
  );
}
